.products {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 2em;
  min-height: 1300px;
}
.badge {
  bottom: 0.5em;
  position: absolute;
  right: 0.5em;
}
.modal.is-active {
  background: #0000008a;
}
.product-image {
  cursor: pointer;
}
.navbar-item img {
  min-height: 4em;
}
#end-of-page {
  height: 150px;
  width: 100%;
}
